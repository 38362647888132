import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtl from 'stylis-plugin-rtl';

const RTLProvider: React.FC = ({ children }: any) => {
  const cache = createCache({ key: 'css-ar', stylisPlugins: [rtl] });
  return <CacheProvider value={cache} children={children} />;
};

export default RTLProvider;
